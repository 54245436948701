<template>
  <main>
    <section>
      <h1>
        Mobile App
      </h1>
    </section>
  </main>
</template>

<script>
export default {
  layout: 'app'
}
</script>
